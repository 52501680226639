import Layout from "./_shared/Layout";
import Banner from "./Banner";
import About from "./About";
import Tokenomics from "./Tokenomics";
import Utility from "./Utility";
import Cards from "./Cards";
import Roadmap from "./Roadmap";

const App = () => {
  return (
    <Layout>
      <Banner />
      <About />
      <Cards />
      <Roadmap />
      <Tokenomics />
      <Utility />
    </Layout>
  );
};

export default App;
