import styled from "styled-components";
import frame from "./images/frame.png";
import Divider from "./_shared/Divider";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 120px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("https://media.discordapp.net/attachments/1105007612040314881/1174520069032788050/0xcookiedev_robots_doing_tasks_in_futuristic_factory_with_many__45bb25a8-1670-4328-9321-cc3d2de86e69.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  text-align: center;

  h2 {
    font-size: 48px;
    color: #fff;
    text-shadow: rgba(114, 106, 106, 0.5) 0px 0px 5px,
      rgba(186, 175, 175, 0.5) 0px 0px 10px;
    font-family: Glitch Goblin;
    text-shadow: rgb(203, 78, 197) 0px 0px 10px, rgb(158, 48, 188) 0px 0px 10px;
    position: relative;
    z-index: 100;
  }

  p {
    color: white;
    line-height: 36px;
    font-family: Orbitron;
    font-size: 18px;
    text-shadow: rgb(187, 31, 112) 0px 0px 10px, rgb(80, 12, 47) 0px 0px 10px;
  }

  @media (min-width: 420px) {
    padding: 120px 50px;
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 760px) {
    p {
      font-size: 20px;
    }
  }

  .shadow {
    background-color: #00000069;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Orbitron;

  p {
    margin-bottom: 60px;
  }

  h3 {
    margin-top: 24px;
    color: rgb(123, 218, 236);
    font-family: Orbitron;
    font-size: 20px;
    text-shadow: rgb(1, 233, 247) 0px 0px 10px, rgb(0, 70, 111) 0px 0px 10px;
  }
`;

const CardsContainer = styled.div`
  max-width: 1180px;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  column-gap: 48px;
  row-gap: 120px;
  column-gap: 96px;
  position: relative;
  z-index: 100;

  @media (min-width: 1080px) {
    grid-template-columns: auto auto;
  }
`;

const Card = styled.div`
  padding: 60px 48px;
  background-color: #000000ab;
  border-radius: 25px;

  position: relative;

  .show {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .border {
    background-image: url(${frame});
    background-repeat: no-repeat;
    background-size: 100%;
    width: 105%;
    height: 135%;
    display: none;
    position: absolute;
    top: -15px;
    left: -15px;
  }

  h3 {
    color: rgb(123, 218, 236);
    font-family: Orbitron;
    font-size: 24px;
    text-shadow: rgb(1, 233, 247) 0px 0px 10px, rgb(0, 70, 111) 0px 0px 10px;
  }

  p {
    font-size: 16px;
  }

  @media (min-width: 360px) {
    padding: 60px 32px;
    margin: auto;
  }

  @media (min-width: 600px) {
    padding: 60px 48px;
    margin: auto;
    width: 500px;

    .border {
      display: block;
    }
  }

  @media (min-width: 1080px) {
    padding: 32px 48px;
    width: unset;
  }

  @media (min-width: 1100px) {
    padding: 48px;
  }

  @media (min-width: 1150px) {
    padding: 60px 48px;
  }

  @media (min-width: 1200px) {
    padding: 78px 48px;
  }
`;

const About = () => {
  return (
    <Section id="utility">
      <div className="shadow"></div>
      <Container>
        <h2>Utility</h2>
        <p>
          Shiba Ainu is not only the a meme kinng between meme coins! It's also
          the smartest doge in crypto and your best cryptocurrency AI Assistant.{" "}
        </p>
      </Container>
      <CardsContainer>
        <Card>
          <div className="show">
            <h3>AI Assistant</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum,
              illo esse blanditiis sequi consequuntur numquam? Lorem ipsum dolor
              sit, amet consectetur adipisicing elit.
            </p>
          </div>
          <div className="border"></div>
        </Card>
        <Card>
          <div className="show">
            <h3>Utility 2</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum,
              illo esse blanditiis sequi consequuntur numquam? Lorem ipsum dolor
              sit, amet consectetur adipisicing elit.
            </p>
          </div>
          <div className="border"></div>
        </Card>
        <Card>
          <div className="show">
            <h3>Utility 3</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum,
              illo esse blanditiis sequi consequuntur numquam? Lorem ipsum dolor
              sit, amet consectetur adipisicing elit.
            </p>
          </div>
          <div className="border"></div>
        </Card>
        <Card>
          <div className="show">
            <h3>Utility 1</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum,
              illo esse blanditiis sequi consequuntur numquam? Lorem ipsum dolor
              sit, amet consectetur adipisicing elit.
            </p>
          </div>
          <div className="border"></div>
        </Card>
      </CardsContainer>
      <Divider />
    </Section>
  );
};

export default About;
