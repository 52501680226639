import styled from "styled-components";
import Divider from "./_shared/Divider";
import logo from "./images/logo.png";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://cdn.midjourney.com/fb95d591-7242-49a5-8082-8f996aa659e9/0_0_2048_N.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  .title {
    position: relative;
    z-index: 100;
    color: #fff;
    color: white;
    text-shadow: rgb(203, 78, 197) 0px 0px 10px, rgb(158, 48, 188) 0px 0px 10px;
    text-align: center;
    position: absolute;
    margin-top: -16rem;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
  }

  h1 {
    font-family: Glitch Goblin;
  }

  .shadow {
    background-color: #00000069;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  @media (min-width: 360px) {
    .title {
      margin-top: -20rem;
    }

    h1 {
      font-size: 60px;
    }
  }

  @media (min-width: 480px) {
    h1 {
      font-size: 70px;
    }
  }

  @media (min-width: 760px) {
    h1 {
      font-size: 90px;
    }
  }

  @media (min-width: 1180px) {
    h1 {
      font-size: 110px;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
  margin-top: 150px;

  a {
    padding: 24px 48px;
    display: -moz-box;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    font-weight: 700;
    font-size: 24px;
    border-radius: 5px;
    border: none;
    color: #7bdaec;
    font-family: Glitch Goblin;
    margin: 0 24px 24px;
    background: #f9b24f;
    color: black;
    text-shadow: rgb(255, 228, 156) 0px 0px 10px, rgb(234, 115, 47) 0px 0px 10px;
  }

  a:hover {
    box-shadow: 4px 4px 15px 3px rgb(0, 0, 0);
  }

  .buy {
    background: black;
    color: white;
    text-shadow: rgb(203, 78, 197) 0px 0px 10px, rgb(158, 48, 188) 0px 0px 10px;
  }

  .buy:hover {
    box-shadow: 4px 4px 15px 3px rgb(203, 78, 197);
  }

  img {
    max-width: 48px;
    margin: 0 14px 0 16px;
  }

  @media (min-width: 360px) {
  }

  @media (min-width: 480px) {
  }

  @media (min-width: 760px) {
    margin-top: 60px;
    flex-direction: row;
  }

  @media (min-width: 1180px) {
    margin-top: 0;

    a {
      font-size: 36px;
      margin: 0 24px 0;
    }
  }
`;

const Banner = () => {
  return (
    <Section id="home">
      <div className="shadow"></div>
      <div className="title">
        <h1>Shiba AInu</h1>
      </div>
      <ButtonsContainer>
        <a href="/#pre-sale">Buy on DEX</a>
        <a href="/#pre-sale" className="buy">
          <img src={logo} alt="Shiba AInu Logo" /> Pre Sale
        </a>
        <a href="/#pre-sale">Airdrop</a>
      </ButtonsContainer>
      <Divider />
    </Section>
  );
};

export default Banner;
