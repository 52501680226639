import styled from "styled-components";
import Divider from "./_shared/Divider";

import bg from "./images/pre-sale-bg.png";
import card1 from "./images/card-1.png";
import card2 from "./images/card-2.png";
import card3 from "./images/card-3.png";
import card4 from "./images/card-4.png";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 100px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-image: url("https://media.discordapp.net/attachments/1105007612040314881/1174516240832790619/0xcookiedev_shiba_inus_jumping_in_a_city_with_many_details_8acca398-0079-4c9b-b1d4-e368f3c6edb8.png?ex=6567e07d&is=65556b7d&hm=5117fabf438e6c3d47b1d1f752c74feebcc20f3ceda205042c1a906d752ff725&=&width=1122&height=629");
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  text-align: center;

  h2 {
    font-size: 48px;
    color: #fff;
    text-shadow: rgba(114, 106, 106, 0.5) 0px 0px 5px,
      rgba(186, 175, 175, 0.5) 0px 0px 10px;
    font-family: Glitch Goblin;
    text-shadow: rgb(203, 78, 197) 0px 0px 10px, rgb(158, 48, 188) 0px 0px 10px;
    position: relative;
    z-index: 100;
  }

  p {
    color: white;
    line-height: 36px;
    font-family: Orbitron;
    font-size: 18px;
    text-shadow: rgb(187, 31, 112) 0px 0px 10px, rgb(80, 12, 47) 0px 0px 10px;
  }

  @media (min-width: 420px) {
    padding: 120px 50px;
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 760px) {
    padding: 100px 16px;

    p {
      font-size: 20px;
    }
  }

  .shadow {
    background-color: #00000069;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
`;

const LeftGrid = styled.div`
  padding: 48px;
  text-align: left;
`;

const RightGrid = styled.div`
  h3 {
    margin-top: 24px;
    color: rgb(123, 218, 236);
    font-family: Orbitron;
    font-size: 20px;
    text-shadow: rgb(1, 233, 247) 0px 0px 10px, rgb(0, 70, 111) 0px 0px 10px;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  img {
    max-width: 300px;
    cursor: pointer;
    transition: transform 0.2s;
    border-radius: 30px;
  }

  img:hover {
    box-shadow: 8px 8px 15px 5px rgb(203, 78, 197);
    transform: scale(1.05);
  }

  h3 {
    margin-top: 24px;
    color: rgb(123, 218, 236);
    font-family: Orbitron;
    font-size: 20px;
    text-shadow: rgb(1, 233, 247) 0px 0px 10px, rgb(0, 70, 111) 0px 0px 10px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const Container1 = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1180px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  img {
    max-width: 300px;
    cursor: pointer;
    transition: transform 0.2s;
    border-radius: 15px;
  }

  img:hover {
    box-shadow: 8px 8px 15px 5px rgb(203, 78, 197);
    transform: scale(1.05);
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }

  .attributes {
    max-width: 100%;
  }
`;

const Presale = () => {
  return (
    <Section id="pre-sale">
      <div className="shadow"></div>
      <h2>Pre-Sale</h2>
      <Container1>
        <RightGrid>
          <img src={card4} alt="" />
          <h3>Legendary</h3>
        </RightGrid>
        <LeftGrid>
          <p>
            Our pre-sale will have a unique way to distribute tokens. You'll be
            able to acquire one of three types of Shiba Ainu cards, each one
            representing a different allocation for the token distribution.
          </p>
          <p>
            You can choose how much you want to commit to the pre-sale. There
            are three types of Shiba Ainu cards: Standard card (which doesn't
            give you bonus tokens), Enhanced card (gives you 5% bonus tokens)
            and Premium card (10% bonus tokens).
          </p>
          <p>
            Last but not least the Legendary Shiba AInu card. This card is
            special, and can't be purchased. There's a small chance you get it
            and it gives you a 100% token bonus.
          </p>
        </LeftGrid>
      </Container1>
      <Container>
        <div className="card-container">
          <img src={card1} alt="" />
          <h3>Standard</h3>
        </div>
        <div className="card-container">
          <img src={card2} alt="" />
          <h3>Enhanced</h3>
        </div>
        <div className="card-container">
          <img src={card3} alt="" />
          <h3>Premium</h3>
        </div>
        {/* <img src={card1} alt="" /> */}
      </Container>
      <Divider />
    </Section>
  );
};

export default Presale;
