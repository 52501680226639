import styled from "styled-components";
import Divider from "./_shared/Divider";
import circle from "./images/circle.png";
import bg from "./images/tokenomics-bg.webp";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 120px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  p {
    color: white;
    line-height: 36px;
    font-size: 18px;
    text-shadow: rgb(187, 31, 112) 0px 0px 10px, rgb(80, 12, 47) 0px 0px 10px;
  }

  @media (min-width: 420px) {
    padding: 120px 50px;
  }

  @media (min-width: 760px) {
    p {
      font-size: 20px;
    }
  }

  .shadow {
    background-color: #00000069;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Orbitron;
  text-align: center;

  h2 {
    font-size: 48px;
    color: #fff;
    text-shadow: rgba(114, 106, 106, 0.5) 0px 0px 5px,
      rgba(186, 175, 175, 0.5) 0px 0px 10px;
    font-family: Glitch Goblin;
    text-shadow: rgb(203, 78, 197) 0px 0px 10px, rgb(158, 48, 188) 0px 0px 10px;
  }

  .pie-chart {
    margin-top: 48px;
    max-width: 350px;
  }

  @media (min-width: 360px) {
    h2 {
      font-size: 50px;
    }
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 992px) {
    max-width: 75%;
    order: unset;
    margin-top: 0;

    h2 {
      font-size: 80px;
    }
  }

  @media (min-width: 1180px) {
    h2 {
      font-size: 80px;
    }
  }
`;

const Tokenomics = () => {
  return (
    <Section id="tokenomics">
      <div className="shadow"></div>
      <Container>
        <h2>Tokenomics</h2>
        <p>
          Shiba AInu is the smartest doge in the world! And it is your most
          capable AI assistant in the cryptocurrency world. Operating based on
          ChatGPT and the vertical industry database of the cryptocurrency
          industry.
        </p>
        <img className="pie-chart" src={circle} alt="" />
      </Container>
      <Divider />
    </Section>
  );
};

export default Tokenomics;
