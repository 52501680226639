import styled from "styled-components";
import logo from "../images/logo.png";
import footerBg from "../images/footer.png";

const FooterContainer = styled.footer`
  position: relative;
  min-height: 350px;
  padding: 80px 50px 0;
  display: flex;
  background: #000;

  background-image: url(${footerBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  .shadow {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1180px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: Orbitron;
`;

const Brand = styled.div`
  width: 100%;
  color: white;

  a {
    color: white;
    text-shadow: rgb(203, 78, 197) 0px 0px 10px, rgb(158, 48, 188) 0px 0px 10px;
    display: flex;
    align-items: center;
  }

  img {
    max-width: 60px;
    margin-right: 15px;
  }

  h4 {
    font-size: 40px;
    font-weight: 600;
    font-family: Glitch Goblin;
  }

  p {
    font-size: 18px;
    margin-top: 20px;
    line-height: 26px;
  }

  @media (min-width: 992px) {
    width: 25%;
  }
`;

const Menus = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .footer-menu {
    font-size: 18px;
    width: auto;
    padding-right: 96px;
    margin-top: 24px;

    h4 {
      margin-bottom: 16px;
      color: #fff;
    }
  }

  .footer-list {
    font-size: 16px;

    li {
      padding: 10px 0;
    }

    a {
      color: rgb(123, 218, 236);
    }
  }

  .socials {
    a {
      display: inline-block;
    }

    img {
      width: 40px;
      margin-right: 16px;
    }
  }

  @media (min-width: 992px) {
    justify-content: flex-end;
    width: 75%;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 0;

    .footer-menu {
      margin-top: 0;
    }
  }
`;

const Copyright = styled.div`
  padding: 24px 0;
  width: 100%;
  margin-top: 66px;
  border-top: 1px solid rgba(197, 46, 200, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  p,
  a {
    font-size: 18px;
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    margin-left: 8px;
    color: #fcbc6a;
    display: flex;
  }
`;

const TeamSection = () => {
  return (
    <FooterContainer>
      <div className="shadow"></div>
      <Container>
        <Brand>
          <a href="/">
            {<img src={logo} alt="Shiba AInu Logo" />}
            <h4>Shiba AInu</h4>
          </a>
          <p>The smartest doge on Crypto</p>
        </Brand>
        <Menus>
          <div className="footer-menu">
            <h4>$AInu</h4>
            <ul className="footer-list">
              <li>
                <a href="/#home">Home</a>
              </li>
              <li>
                <a href="/#honey">What is Shiba AInu?</a>
              </li>
              <li>
                <a href="/#tokenomics">Tokenomics</a>
              </li>
              <li>
                <a href="/#meme">Meme</a>
              </li>
              <li>
                <a href="/#utility">Utility</a>
              </li>
            </ul>
          </div>
          <div className="footer-menu">
            <h4>Info</h4>
            <ul className="footer-list">
              <li>
                <a href="/#roadmap">Roadmap</a>
              </li>
              <li>
                <a href="/#faq">FAQ</a>
              </li>
            </ul>
          </div>
          <div className="footer-menu socials">
            <h4>Social</h4>
            <a
              href="https://discord.com/invite/QkHV7cjJKE"
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: -6 }}
            >
              {/* <img src={discordIcon} alt="discord icon" /> */}
            </a>
            <a
              href="https://twitter.com/Honey_solcoin"
              target="_blank"
              rel="noreferrer"
            >
              {/* <img src={twitterIcon} alt="twitter icon" /> */}
            </a>
          </div>
        </Menus>
        <Copyright>
          <p>© Shiba AInu on Solana</p>
          <a href="https://solana.com/" target="_blank" rel="noreferrer">
            <img
              src="https://s2.coinmarketcap.com/static/img/coins/200x200/5426.png"
              alt="solana icon"
              style={{ width: 24 }}
            />
          </a>
        </Copyright>
      </Container>
    </FooterContainer>
  );
};

export default TeamSection;
