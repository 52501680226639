import styled from "styled-components";
import MobileLine from "../images/mobile-divider.png";
import Line from "../images/divider.png";

const Divider = styled.img`
  width: 100%;
  position: absolute;
  bottom: -10px;
  z-index: 100;
  opacity: 0.75;

  @media (min-width: 480px) {
    bottom: -18px;
  }

  @media (min-width: 600px) {
    bottom: -26px;
  }

  @media (min-width: 1150px) {
    bottom: -50px;
  }
`;

const Header = () => {
  return <Divider src={Line} alt="" />;
};

export default Header;
