import styled from "styled-components";
import Divider from "./_shared/Divider";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import bg from "./images/roadmap-bg.png";
import logo from "./images/logo.png";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 120px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  p {
    color: white;
    line-height: 36px;
    font-size: 18px;
    text-shadow: rgb(187, 31, 112) 0px 0px 10px, rgb(80, 12, 47) 0px 0px 10px;
  }

  @media (min-width: 420px) {
    padding: 120px 50px;
  }

  @media (min-width: 760px) {
    p {
      font-size: 20px;
    }
  }

  .shadow {
    background-color: #00000069;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  max-width: 1180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Orbitron;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const LeftGrid = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  margin-top: 80px;

  order: 1;
  text-align: center;

  h2 {
    font-size: 48px;
    color: #fff;
    text-shadow: rgba(114, 106, 106, 0.5) 0px 0px 5px,
      rgba(186, 175, 175, 0.5) 0px 0px 10px;
    font-family: Glitch Goblin;
    text-shadow: rgb(203, 78, 197) 0px 0px 10px, rgb(158, 48, 188) 0px 0px 10px;
  }

  .socials {
    a {
      display: inline-block;
    }

    img {
      width: 40px;
      margin-right: 16px;
    }
  }

  @media (min-width: 360px) {
    h2 {
      font-size: 50px;
    }
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 992px) {
    max-width: 75%;
    order: unset;
    margin-top: 0;

    h2 {
      font-size: 80px;
    }
  }

  @media (min-width: 1180px) {
    h2 {
      font-size: 80px;
    }
  }

  .no-shadow {
    img {
      max-width: 60px;
      height: auto;
    }

    p {
      font-size: 16px;
      line-height: 32px;
    }
  }

  .vertical-timeline::before {
    width: 2px;
    background-color: #7bdaec;
    box-shadow: 0px 2px 5px 2px rgb(123, 218, 236);
  }

  .no-shadow .vertical-timeline-element-content {
    box-shadow: unset;
  }

  .vertical-timeline-element-title {
    font-size: 24px;
    color: #fff;
    text-shadow: rgba(114, 106, 106, 0.5) 0px 0px 5px,
      rgba(186, 175, 175, 0.5) 0px 0px 10px;
    font-family: Glitch Goblin;
    text-shadow: rgb(203, 78, 197) 0px 0px 10px, rgb(158, 48, 188) 0px 0px 10px;
  }

  .vertical-timeline-element-subtitle {
    color: #7bdaec;
  }
`;

const Roadmap = () => {
  return (
    <Section id="roadmap">
      <div className="shadow"></div>
      <Container>
        <LeftGrid>
          <h2>Roadmap</h2>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="no-shadow"
              contentStyle={{
                background: "unset",
                color: "",
                border: "2px solid rgb(123, 218, 236)",
                boxShadow: "0px 2px 5px 2px rgb(123, 218, 236)",
                borderRadius: "10px",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #7790da" }}
              date=""
              iconStyle={{ color: "" }}
              icon={<img src={logo} alt="Minguin Launch" />}
            >
              <h3 className="vertical-timeline-element-title">
                Launching Shiba AInu
              </h3>
              <h4 className="vertical-timeline-element-subtitle">Pre-sale</h4>
              <p>
                $AINU SPL token launched. Fair and transparent sales process,
                with a focus on equitable distribution.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="no-shadow"
              contentStyle={{
                background: "unset",
                color: "",
                border: "2px solid rgb(123, 218, 236)",
                boxShadow: "0px 2px 5px 2px rgb(123, 218, 236)",
                borderRadius: "10px",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #7790da" }}
              date=""
              iconStyle={{ color: "" }}
              icon={<img src={logo} alt="Minguin Shredder" />}
            >
              <h3 className="vertical-timeline-element-title">
                Continue Promotion
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Community engagement
              </h4>
              <p>
                Highlight the unique aspects and benefits of each NFT tier.
                Maintain interest and involvement post-sale.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="no-shadow"
              contentStyle={{
                background: "unset",
                color: "",
                border: "2px solid rgb(123, 218, 236)",
                boxShadow: "0px 2px 5px 2px rgb(123, 218, 236)",
                borderRadius: "10px",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #7790da" }}
              date=""
              iconStyle={{ color: "" }}
              icon={<img src={logo} alt="Minguin DAO" />}
            >
              <h3 className="vertical-timeline-element-title">
                Secondary market support
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Speculation time.
              </h4>
              <p>
                Facilitate trading of pre-sale NFTs on secondary markets. Using
                NFTs for fundraising is innovative. It attracts not only
                cryptocurrency enthusiasts but also those interested in digital
                art and AI, broadening the project's appeal and reach.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="no-shadow"
              contentStyle={{
                background: "unset",
                color: "",
                border: "2px solid rgb(123, 218, 236)",
                boxShadow: "0px 2px 5px 2px rgb(123, 218, 236)",
                borderRadius: "10px",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #7790da" }}
              date=""
              iconStyle={{ color: "" }}
              icon={<img src={logo} alt="$MINGUI token" />}
            >
              <h3 className="vertical-timeline-element-title">
                Access to AI Services
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Smart Doge time!
              </h4>
              <p>
                Offer token holders access to user-friendly AI tools for data
                analysis or simple predictive tasks. .
              </p>
              <p>
                AI Educational Resources: Use tokens to unlock AI-related
                educational content, like tutorials, webinars, or introductory
                courses. This is a low-barrier way to add value to the token
                while promoting AI literacy.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="no-shadow"
              contentStyle={{
                background: "unset",
                color: "",
                border: "2px solid rgb(123, 218, 236)",
                boxShadow: "0px 2px 5px 2px rgb(123, 218, 236)",
                borderRadius: "10px",
              }}
              contentArrowStyle={{ borderRight: "7px solid  #7790da" }}
              date=""
              iconStyle={{ color: "" }}
              icon={<img src={logo} alt="$MINGUI token" />}
            >
              <h3 className="vertical-timeline-element-title">
                Continue Development
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Innovation time
              </h4>
              <p>
                Continue developments to offer more value to $AINU holders.
                These include: Access to more AI services, decentralized AI
                marketplace, and much more!
              </p>
              <p>
                Check out the documentation for a more complete view of our
                future plans.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ color: "" }}
              // icon={<StarIcon />}
            />
          </VerticalTimeline>
        </LeftGrid>
      </Container>
      <Divider />
    </Section>
  );
};

export default Roadmap;
